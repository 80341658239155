import './App.css';

import {StiOptions, Stimulsoft} from 'stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor';

import React from 'react';

class App extends React.Component {
    private options: any;
    private designer: any;

    render() {
        return (
            <div id="designer" className="App">
            </div>
        );
    }

    componentDidMount() {
        console.log('Loading Designer view');

        console.log('Chargin key');
	Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHlMwo/boI70aVvWxvBq11Gc4BMBNyp35geWPoreaOiRhOfQuU" + 
"sidoOINjDu0H4Ir04bk+xVCSmi++rLaDlRt+70bZa+rJj8xDgpQOA15RH9nCsSrf9Yo2wnzQexdH1tQzF7GdMp97ea" + 
"u8+HkRyd1n+EjzYLgBMrA+5LW0Ee5Mkuf5MnVlMdpjAr3+tDtKu9OWE2VbCdGLNh3NGLVLVEZwMV1qJ2Eqcb6V2jsw" + 
"4l/DDbmQisAg3nuHoXGczYa8CNXw2N77Vhg0frL0Jc5mSFB6M0U9p3jqdfViP3pX+ZV4jaZOLVsUxAhIr0zgLKOIjw" + 
"sge2eHA2sSyDa0xG+1lRKMCbHf44mY6+L8mmP5rbuVm5qOzVGw/x+EoaxCbEhc6e6nDVxRLbWbkQRtF0Zp0zVOWvNN" + 
"eCwE8AM3qZMvfEcWMsfN5OD79bXf8SRnZDHl7R5kg70SpLNAdvePV5r3uLRowjvkUI7re+DonKO7H28rRnvWkdkBlH" + 
"epoWiMPtS6UGrjN6Mg3RkM3ya+mmh8ZEsx6+";

        console.log('Set full screen mode for the designer');
        this.options = new Stimulsoft.Designer.StiDesignerOptions();

        StiOptions.WebServer.url = window.location.origin.replace('report','adapter');
        console.log(`Set adapter to ${StiOptions.WebServer.url}`);

        this.options.appearance.fullScreenMode = false;
        this.options.width = '100vw';
        this.options.height = '100vh';
        console.log('Create the report designer with specified options');
        this.designer = new Stimulsoft.Designer.StiDesigner(this.options, 'StiDesigner', false);

        console.log('Edit report template in the designer');
        this.designer.report = new Stimulsoft.Report.StiReport();

        console.log('Load report from url');
        this.designer.report.loadFile('/reports/SimpleList.mrt');

        console.log('Rendering the designer to selected element');
        this.designer.renderHtml('designer');

        console.log('Loading completed successfully!');
    }

}

export default App;
